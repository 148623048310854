<template>

    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">
            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>
                        
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{$t("Порядок_согласования")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <v-select
                                    v-model="parallel"
                                    :items="adjustmentTypes"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                </v-select>
                            </v-col>
                        </v-row>

                        <v-row class="full-width-row" no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Согласующие") }}</label>
                            </v-col>

                            <v-col  cols="12" sm="12" md="9">
                                <div class="icon-click-row-group icrg-align-center">   

                                    <div
                                        @click="selectAdjusters"
                                    >
                                        <v-popover>
                                            <v-icon
                                                small
                                            >
                                                fas fa-edit
                                            </v-icon>

                                            <template slot="popover">
                                                {{ $t("Выбрать_согласующих") }}
                                            </template>

                                        </v-popover>

                                    </div>

                                    <div class="onlyReadData more-per-lab-wrap">
                                        
                                        <template v-for="(adjuster, index) in adjusters">

                                            <v-workplace-chip :key="adjuster.workplaceId" :id="adjuster.workplaceId" :name="adjuster.name" />

                                            <v-icon
                                                v-if="!parallel && adjusters.indexOf(adjuster) +1 != adjusters.length"
                                                :key="index"
                                                small
                                            >
                                                fas fa-angle-right
                                            </v-icon>

                                        </template>

                                    </div>

                                </div>
                            </v-col>
                            
                        </v-row>

                    </v-card-text>

                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="valid"
                >
                    {{ $t("Ок") }}
                </v-btn>

                <v-btn 
                    color="blue-grey" 
                    text
                    depressed
                    @click="cancel"
                >
                    {{$t("Отмена")}}
                </v-btn>

            </v-card-actions>

        </v-card>

    </v-dialog>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "BeginAdjustmentDlg",
    data: () => ({
        title: "Лист_согласования",
        options: {
            color: 'grey lighten-3',
            width: 400,
            zIndex: 200,
            noconfirm: false,
        },
    }),
    computed: {
        ...mapGetters('dialogs/beginAdjustment', { visible: 'isVisible', valid: 'isValid' }),
        ...mapGetters('references', { adjustmentTypes: 'GetAdjustmentTypes' }),
        parallel: {
            get: function() {
                return this.$store.getters['dialogs/beginAdjustment/isParallel'];
            },
            set: function(v) {
                this.$store.commit('dialogs/beginAdjustment/SET_PARALLEL', v);
            }
        },
        adjusters: {
            get: function() {
                return this.$store.getters['dialogs/beginAdjustment/getAdjusters'];
            },
            set: function(v) {
                this.$store.commit('dialogs/beginAdjustment/SET_ADJUSTERS', v);
            }
        },
    },
    methods: {
        ...mapActions('dialogs/beginAdjustment', ['ok', 'cancel', 'selectAdjusters']),
    }
}
</script>